import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { IoChevronForwardSharp } from "react-icons/io5";
import { FaArrowRight } from "react-icons/fa6";


import Header from '../header/header';
import Footer from '../Footer/Footer';
import Resourcesc2 from './Resources1-componat/Resourcesc2';



const Resources2 = () => {
  return (
    <>


<Header/>  
<Resourcesc2/>
<Footer/>
        
    </>
  )
}

export default Resources2