import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { IoChevronForwardSharp } from "react-icons/io5";
import { FaArrowRight } from "react-icons/fa6";


import Header from '../header/header';
import Footer from '../Footer/Footer';
import Resourcesc3 from './Resources1-componat/Resourcesc3';



const Resources3 = () => {
  return (
    <>


<Header/>  
<Resourcesc3/>
<Footer/>
        
    </>
  )
}

export default Resources3